import React from "react";
import { SimpleReveal } from "simple-reveal";

import * as css from "./EventSection2.css";
import { link } from "./EventSection1.css";
import Background from "../assets/image/background.png";
import { ReactComponent as EventBadge } from "../assets/event2.svg";
import eventTitle from "../assets/image/eventTitle2.png";
import infoEvent2 from "../assets/image/infoEvent2.png";
import giveaway from "../assets/image/giveaway.png";
import { useSiteMetadata } from "../siteMetadata";
import { appInfo } from "../InApp";

const EventSection2 = () => {
  const { event2 } = useSiteMetadata();

  const hadnleFavoriteClick = async (e: any) => {
    e.preventDefault();

    const genScheme = (utm_source: string) =>
      `karrot://minikarrot/router?app=https%3A%2F%2Fwebview.prod.kr.karrotmarket.com%2Fbusiness-platform%2Fhome&path=%2Fposts%2Ftheme%2F51%2Fprofiles%3Fentry%3Dlanding_owned_inapp_smbday_2211_${utm_source}%26referrer%3Dlanding_owned_inapp_smbday_2211_${utm_source}&navbar=false&scrollable=false`;

    if (appInfo.ios || appInfo.android) {
      window.location.href = genScheme("instagram");
      return;
    }

    window.location.href = event2;
  };

  return (
    <section className={css.backgroundContainer}>
      <img src={Background} alt="background" className={css.image} />
      <div className={css.container}>
        <div className={css.defaultWrapper}>
          <EventBadge />
        </div>
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.defaultWrapper)} style={style}>
              <img src={eventTitle} alt="이벤트 제목" className={css.title} />
            </div>
          )}
          delay={0}
        />
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.descriptionWrapper)} style={style}>
              우리동네 가게 <strong>3곳 이상</strong>과 단골을 맺으면 <br />
              추첨을 통해 다양한 선물을 드려요
            </div>
          )}
          delay={100}
        />
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.infoWrapper)} style={style}>
              <img src={infoEvent2} alt="단골맺기 이벤트 설명" className={css.imageWapper} />
            </div>
          )}
          delay={200}
        />
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.wrapper)} style={style}>
              <img src={giveaway} alt="선물" className={css.giveawayWrapper} />
            </div>
          )}
          delay={300}
        />
        <a href="#" onClick={hadnleFavoriteClick} className={link}>
          지금 단골 맺으러 가기
        </a>
      </div>
    </section>
  );
};

export default EventSection2;
