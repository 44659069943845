import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as css from "./RendingVideo.css";
import heroWebm from "../assets/video/heroCover.webm";
import heroMp4 from "../assets/video/heroCover.mp4";
import videoPoster from "../assets/image/videoPoster.png";
import arrow from "../assets/arrow.svg";
import { ReactComponent as Title } from "../assets/title.svg";
import { ReactComponent as Heart } from "../assets/heart.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";

const RendingVideo = () => {
  return (
    <div className={css.container}>
      <section className={css.videoSection}>
        <video className={css.video} autoPlay loop muted playsInline>
          <source src={heroWebm} type="video/webm" />
          <source src={heroMp4} type="video/mp4" />
        </video>
        <StaticImage src={videoPoster} alt="videoPoster" className={css.videoPoster} />
      </section>
      <div className={css.contentSection}>
        <span className={css.logoWrapper}>
          <Logo />
        </span>
        <h3 className={css.subtitle}>2022 소상공인의 날 캠페인</h3>
        <div className={css.titleWrapper}>
          <Title />
        </div>
        <div className={css.description}>
          <div className={css.textWrapper}>
            <div className={css.heartWrapper}>
              <Heart />
            </div>
            <p className={css.text}>
              당근비즈니스와 함께 성장하는 <br />
              우리동네 사장님을 응원해요
            </p>
          </div>
        </div>
        <div className={css.arrowWrapper}>
          <img src={arrow} alt="화살표" />
        </div>
      </div>
    </div>
  );
};

export default RendingVideo;
