import React from "react";
import Youtube from "react-youtube";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

import { useSiteMetadata } from "../siteMetadata";
import * as css from "./YoutubeSection.css";

const YoutubeSection = () => {
  const { youtubeId } = useSiteMetadata();
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "image/youtubeBackground.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, PNG])
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);

  return (
    <section className={css.container}>
      <div className={css.videoBackground}>
        <BackgroundImage Tag="section" {...bgImage} className={css.videoWrapper}>
          <div className={css.youtubeWrapper}>
            <Youtube
              className={css.iframe}
              videoId={youtubeId}
              opts={{
                width: "100%",
                height: "100%",
                playerVars: {
                  playsinline: 1,
                },
              }}
            />
          </div>
        </BackgroundImage>
      </div>
      <p className={css.videoDescription}>
        {`한 송이 꽃에 애정을 담고
        한 그릇 음식에 진심을 담아
        이웃에게 전하는 우리 동네 사장님

        오늘도 당근비즈니스와 함께
        조금씩 성장하고 있어요`}
      </p>
    </section>
  );
};

export default YoutubeSection;
