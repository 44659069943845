import React from "react";
import { SimpleReveal } from "simple-reveal";

import EventDescription from "./EventDescription";
import * as css from "./EventSection3.css";
import { ReactComponent as EventBadge } from "../assets/event3.svg";
import eventTitle from "../assets/image/eventTitle3.png";
import { ReactComponent as Share } from "../assets/share.svg";
import zeropayCard from "../assets/image/zeropay.png";
import { ShareContext } from "../share";

const EventSection3 = () => {
  const { share } = React.useContext(ShareContext);

  return (
    <section className={css.container}>
      <div className={css.wrapper}>
        <EventBadge />
      </div>
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.wrapper)} style={style}>
            <img src={eventTitle} alt="이벤트 제목" className={css.title} />
          </div>
        )}
      />
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.cardImgWrapper)} style={style}>
            <img src={zeropayCard} alt="제로페이 카드" className={css.imageWrapper} />
          </div>
        )}
      />
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.descriptionWrapper)} style={style}>
            <EventDescription title="당첨 인원" description="100명" />
            <EventDescription title="참여 기간" description="11월 9일 - 11월 22일" />
          </div>
        )}
      />
      {share && (
        <div className={css.buttonWrapper}>
          <button
            type="button"
            id="share"
            className={css.shareButton}
            onClick={() => {
              void share({
                url: "https://daangn.onelink.me/oWdR/ulfdtf16",
                text: "소상공인의 날 이벤트 참여하고 다양한 선물을 받으세요.",
              });
            }}
          >
            <Share className={css.shareIcon} width={32} height={32} />
          </button>
          <label htmlFor="share" className={css.shareText}>
            공유하기
          </label>
        </div>
      )}
    </section>
  );
};

export default EventSection3;
