import React from "react";

import * as css from "./Footer.css";

interface Props {
  children: React.ReactNode;
}

const ListItem = (props: Props) => {
  return (
    <li className={css.li}>
      <span>{props.children}</span>
    </li>
  );
};

const Footer = () => {
  return (
    <footer className={css.container}>
      <h2 className={css.title}>참여하기 전 확인해 주세요</h2>
      <div className={css.notice}>
        <h4 className={css.noticeTitle}>이벤트 기간</h4>
        <ul className={css.ul}>
          <ListItem>11월 9일(수) - 11월 22일(화) 14일 간</ListItem>
        </ul>
      </div>
      <div className={css.notice}>
        <h4 className={css.noticeTitle}>당첨자 발표</h4>
        <ul className={css.ul}>
          <ListItem>11월 30일(수)</ListItem>
        </ul>
      </div>
      <div className={css.notice}>
        <h4 className={css.noticeTitle}>이벤트1 유의사항</h4>
        <ul className={css.ul}>
          <ListItem>쿠폰 코드를 입력하면 광고캐시가 적립됩니다.</ListItem>
          <ListItem>1개의 광고 계정에, 최대 1회 쿠폰 등록이 가능합니다.</ListItem>
        </ul>
      </div>
      <div className={css.notice}>
        <h4 className={css.noticeTitle}>이벤트2 유의사항</h4>
        <ul className={css.ul}>
          <ListItem>우리동네 가게와 3곳 이상 단골을 맺으면 자동으로 이벤트에 참여됩니다.</ListItem>
          <ListItem>가게 후기를 남기면 당첨 확률이 높아집니다.</ListItem>
          <ListItem>참여하신 분들 중 120분을 추첨하여 아트방 사장님이 제작한 무드등과 당근 굿즈를 드립니다.</ListItem>
          <ListItem>당첨자에게는 개별 알림을 드립니다.</ListItem>
        </ul>
      </div>
      <div className={css.notice}>
        <h4 className={css.noticeTitle}>이벤트3 유의사항</h4>
        <ul className={css.ul}>
          <ListItem>페이지를 공유하면 자동으로 이벤트에 참여됩니다.</ListItem>
          <ListItem>참여하신 분들 중 100분을 추첨하여 제로페이 1만 원 상품권을 드립니다.</ListItem>
          <ListItem>당첨자에게는 개별 알림을 드립니다.</ListItem>
        </ul>
      </div>
      <div className={css.notice}>
        <h4 className={css.noticeTitle}>문의: 당근마켓 고객센터</h4>
        <ul className={css.ul}>
          <ListItem>이벤트 1 : 1644-9736</ListItem>
          <ListItem>이벤트 2, 3 : 1877-9736</ListItem>
          <ListItem>{"평일 10시-19시 / 점심시간 13시-14시 (토요일 및 공휴일 휴무)"}</ListItem>
          <ListItem>{"[나의 당근 > 자주 묻는 질문 > 고객센터에 문의하기]"}</ListItem>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
