import { graphql, useStaticQuery } from "gatsby";

export function useSiteMetadata() {
  const data = useStaticQuery<Queries.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
          youtubeId
          event1
          event2
        }
      }
    }
  `);

  return data.site!.siteMetadata;
}
