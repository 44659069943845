import React from "react";

import * as css from "./EventDescription.css";

interface Props {
  title: string;
  description: string;
}

const EventDescription = (props: Props) => {
  return (
    <div className={css.container}>
      <div className={css.title}>{props.title}</div>
      <div className={css.descriptioin}>{props.description}</div>
    </div>
  );
};

export default EventDescription;
