type IOS =
  | {
      postMessage(message: string): void;
    }
  | undefined;

type Android =
  | {
      messageHandler: (message: string) => void;
    }
  | undefined;

type AppInfo = { ios: IOS; android: Android };

const generateAppInfo = (): AppInfo => {
  const appInfo: AppInfo = {
    ios: undefined,
    android: undefined,
  };

  try {
    appInfo.ios = window.webkit?.messageHandlers.messageHandler;
    appInfo.android = window.AndroidFunction;
  } catch (error) {
    appInfo.ios = undefined;
    appInfo.android = undefined;
  }

  return appInfo;
};

export const appInfo = generateAppInfo();
