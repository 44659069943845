import * as React from "react";
import Modal from "react-modal";
import { type PageProps, type HeadProps } from "gatsby";
import { HeadSeo, OpenGraph, TwitterCard } from "gatsby-plugin-head-seo/src";
import { installKarrotBridgeDriver, makeKarrotBridge } from "@daangn/karrotbridge";

import { pageWrapper } from "../styles/utils.css";
import * as css from "../styles/index.css";
import RendingVideo from "../components/RendingVideo";
import YoutubeSection from "../components/YoutubeSection";
import EventSection1 from "../components/EventSection1";
import EventSection2 from "../components/EventSection2";
import EventSection3 from "../components/EventSection3";
import Footer from "../components/Footer";
import ogImage from "../assets/image/OG_V1.jpg";
import { getCurrentEnvironment } from "../bridge";
import { ShareContext, initialValue as initialShare } from "../share";

type ModalState = {
  url: string;
  text: string;
};

const IndexPage: React.FC<PageProps> = () => {
  const [share, setShare] = React.useState(initialShare);
  const [modalState, setModalSate] = React.useState<ModalState | null>(null);

  const closeModal = React.useCallback(() => {
    setModalSate(null);
  }, []);

  React.useEffect(() => {
    const env = getCurrentEnvironment();
    switch (env) {
      case "Web": {
        if ("share" in navigator) {
          setShare({
            async share(props) {
              try {
                await navigator.share(props);
              } catch (e: any) {
                if (e instanceof DOMException && e.name == "AbortError") {
                  // noop
                } else {
                  setShare({
                    share(props) {
                      setModalSate(props);
                      return Promise.resolve();
                    },
                  });
                }
              }
            },
          });
        } else {
          setShare({
            share(props) {
              setModalSate(props);
              return Promise.resolve();
            },
          });
        }
        break;
      }
      default: {
        const { driver, uninstall } = installKarrotBridgeDriver();
        const bridge = makeKarrotBridge({ driver });
        setShare({
          async share(props) {
            try {
              await bridge.share({ share: props });
            } catch {
              try {
                await navigator.share(props);
              } catch (e: any) {
                if (e instanceof DOMException && e.name == "AbortError") {
                  // noop
                } else {
                  setShare({
                    share(props) {
                      setModalSate(props);
                      return Promise.resolve();
                    },
                  });
                }
              }
            }
          },
        });
        return () => {
          uninstall();
        };
      }
    }
  }, []);

  return (
    <ShareContext.Provider value={share}>
      <main className={pageWrapper}>
        <RendingVideo />
        <YoutubeSection />
        <EventSection1 />
        <EventSection2 />
        <EventSection3 />
        <Footer />
        {modalState && (
          <Modal
            isOpen
            style={{
              content: {
                height: "fit-content",
                maxWidth: "30rem",
                backgroundColor: "#FFF2D5",
                border: "none",
                margin: "auto",
              },
            }}
            contentLabel={modalState.text}
            onRequestClose={closeModal}
            ariaHideApp={false}
          >
            <div className={css.modalWrapper}>
              <p className={css.title}>아래 {`텍스트를 복사해서\n SNS에 공유해주세요.`}</p>
              <textarea
                className={css.textarea}
                readOnly
                autoFocus
                onSelect={(e) => {
                  e.currentTarget.select();
                }}
                rows={10}
                value={[modalState.text, modalState.url, "#당근마켓 #소상공인의날"].join("\n\n")}
              />
              <button className={css.closeButton} onClick={closeModal}>
                닫기
              </button>
            </div>
          </Modal>
        )}
      </main>
    </ShareContext.Provider>
  );
};

export default IndexPage;

export const Head: React.FC<HeadProps> = ({ location }) => {
  return (
    <HeadSeo location={location}>
      {(props) => [
        <OpenGraph
          key="og"
          og={{
            ...props,
            type: "website",
            images: [
              {
                url: new URL(ogImage, props.url),
              },
            ],
          }}
        />,
        <TwitterCard
          key="tc"
          card={{
            ...props,
            type: "summary_large_image",
            site: "@daangn",
            image: {
              url: new URL(ogImage, props.url),
            },
          }}
        />,
      ]}
    </HeadSeo>
  );
};
