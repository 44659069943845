import { createContext } from "react";

/**
 * @See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
 */
interface ShareContext {
  share?(props: { url: string; text: string }): Promise<void>;
}

export const initialValue: ShareContext = {
  share: undefined,
};

export const ShareContext = createContext(initialValue);
ShareContext.displayName = "ShareContext";
