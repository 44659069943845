import React from "react";
import { SimpleReveal } from "simple-reveal";

import * as css from "./EventSection1.css";
import { ReactComponent as EventBadge } from "../assets/event1.svg";
import eventTitle from "../assets/image/eventTitle1.png";
import coupon from "../assets/image/coupon.png";
import infoEvent1 from "../assets/image/infoEvent1.png";
import { useSiteMetadata } from "../siteMetadata";
import { appInfo } from "../InApp";

const EventSection1 = () => {
  const { event1 } = useSiteMetadata();

  const handleGetCouponClick = async (e: any) => {
    e.preventDefault();

    if (appInfo.ios || appInfo.android) {
      window.location.href =
        "karrot://minikarrot/router?remote=https%3A%2F%2Fkotisaari.karrotwebview.com%2Ffinances%2Fevent-coupon%3Frecent%3Dtrue%26utm_source%3Dlanding_owned_inapp_smbday_2211%26referrer%3Dlanding_owned_inapp_smbday_2211&present=top&navbar=false&scrollable=false";

      return;
    }

    window.location.href = event1;
  };

  return (
    <section className={css.container}>
      <div className={css.wrapper}>
        <EventBadge />
      </div>
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.wrapper)} style={style}>
            <img src={eventTitle} alt="이벤트 제목" className={css.title} />
          </div>
        )}
        delay={0}
      />
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.wrapper)} style={style}>
            <img src={coupon} alt="쿠폰" className={css.imageWrapper} />
          </div>
        )}
        delay={100}
      />
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.wrapper)} style={style}>
            <p className={css.descriptionTitle}>광고캐시 받는 방법</p>
            <div className={css.infoWrapper}>
              <img src={infoEvent1} alt="쿠폰 등록 방법" className={css.infoWrapper} />
            </div>
          </div>
        )}
        delay={200}
      />
      <a href="#" onClick={handleGetCouponClick} className={css.link}>
        지금 쿠폰 등록하러 가기
      </a>
    </section>
  );
};

export default EventSection1;
